import React from "react";
import { SvgIcon } from "@mui/material";

const IVCompatibilityIcon = (props: any) => {

    return (
        <SvgIcon {...props} viewBox="0 0 89 88">
            <path d="m35.7 16.2c-19.6 0.017-35.7 16.1-35.7 35.8s16.1 35.8 35.8 35.8l14.4 8e-3c3.41 3e-3 5.68-2.26 5.68-5.04s-2.27-5.05-4.98-5.05c-3 3e-3 -6.09-0.139-9.17-0.414-5.33-0.478-9.68-4.62-9.68-9.99s4.37-9.38 9.71-9.78c1.04-0.078 1.12-0.181 3.1-0.315 1.4-0.095 5.33-2.19 5.33-5.14s-2.41-5.21-5.33-5.34c-1.11-0.049-2.2-0.091-3.29-0.277-5.17-0.875-9.52-4.56-9.52-9.8s4.28-9.52 9.52-9.52l3.28-0.196c2.94-0.027 5.34-2.43 5.34-5.38 0-2.95-2.4-5.35-5.32-5.37h-9.16z" />
            <path d="m53 72.4c19.6-0.017 35.7-16.1 35.7-35.8s-16.1-35.8-35.8-35.8l-14.4-8e-3c-3.41-3e-3 -5.68 2.26-5.68 5.04s2.27 5.05 4.98 5.05c3-3e-3 6.09 0.139 9.17 0.414 5.33 0.478 9.68 4.62 9.68 9.99s-4.37 9.38-9.71 9.78c-1.04 0.078-1.12 0.181-3.1 0.315-1.4 0.095-5.33 2.19-5.33 5.14s2.41 5.21 5.33 5.34c1.11 0.049 2.2 0.091 3.29 0.277 5.17 0.875 9.52 4.56 9.52 9.8s-4.28 9.52-9.52 9.52l-3.28 0.196c-2.94 0.027-5.34 2.43-5.34 5.38 0 2.95 2.4 5.35 5.32 5.37h9.16z" />
        </SvgIcon>
    );

}

export default IVCompatibilityIcon;