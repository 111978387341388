import React from "react";
import { SvgIcon } from "@mui/material";

const Logo = (props: any) => {

    return (
        <SvgIcon {...props} viewBox="0 0 686.2 170.7">
            <path fill="#023B59" d="M218.9,84.3l-14.3-8.7c-4.8-2.8-6.1-4.4-6.1-7.4c0-3.4,1.8-5.7,10.8-5.7c5.3,0,9.9,0.7,14.1,2.1
		c1.9,0.5,2.9,0.5,3.6,0c0.8-0.6,1-1.9,1-3.8V59c0-3.5-1.1-4.4-4.7-5.5c-4.2-1.4-8.9-2-14.1-2c-15.3,0-22.7,5.4-22.7,16.4
		c0,7.1,3.5,12.7,10.3,16.8l13.9,8.3c3.5,2.1,5.6,3.7,6.7,4.8c1.1,1.1,1.7,2.6,1.7,4.5c0,4.8-3.4,6.5-12.5,6.5
		c-5.6,0-11.1-0.8-16.1-2.4c-1.7-0.6-2.8-0.6-3.6-0.1c-0.8,0.6-1.2,1.8-1.2,3.9v1.6c0,3.6,0.9,4.3,4.4,5.5
		c4.8,1.5,10.2,2.3,16.3,2.3c16.5,0,24.5-5.7,24.5-17.5C231,93.4,226.6,88.9,218.9,84.3"/>
            <path fill="#023B59" d="M248.2,52.4h-1.3c-4,0-5.1,1.1-5.1,5.1v56c0,4,1.1,5.1,5.1,5.1h1.3c4,0,5.1-1.1,5.1-5.1v-56
		C253.3,53.5,252.2,52.4,248.2,52.4"/>
            <path fill="#023B59" d="M336.4,67c-0.8-10.8-5-15.2-14.3-15.2c-8.5,0-12.6,4.5-14,15.2l-4.7,38.8c-0.4,3.2-0.8,3.2-1.6,3.2
		c-1,0-1.3,0-1.7-3.2L295.4,67c-1.3-10.8-5.4-15.2-14-15.2c-9.2,0-13.4,4.6-14.3,15.2l-3.4,46.5c-0.2,2.1,0.1,3.3,0.8,4.1
		c0.7,0.8,1.9,1.1,4.1,1.1h1.5c4.1,0,5.3-1.2,5.4-5.1l3.1-46.4c0.3-4.1,1.3-5,2.9-5c1.7,0,2.6,0.7,3.1,5l5.5,40.4
		c1.1,8,4.4,11.1,11.6,11.1c7.6,0,10.5-2.7,11.6-11.1l5.4-40.4c0.5-4.6,1.6-5,3-5c1.9,0,2.7,0.7,3,5l3.2,46.4c0.3,4,1.5,5.1,5.4,5.1
		h1.5c2.2,0,3.3-0.3,4.1-1.1c0.7-0.8,0.9-2,0.8-4.1L336.4,67z"/>
            <path fill="#023B59" d="M376.8,52.4h-15.6c-8.3,0-12,3.7-12,12v49.2c0,4,1.2,5.1,5.1,5.1h1.3c4,0,5.1-1.1,5.1-5.1V96h16
		c14.9,0,22.5-7.3,22.5-21.8C399.3,59.5,391.9,52.4,376.8,52.4 M376.8,85h-16V66.7c0-2.3,1.1-3.3,3.4-3.3h12.6
		c8,0,10.9,2.9,10.9,10.8C387.7,82.2,384.9,85,376.8,85"/>
            <path fill="#023B59" d="M433.8,51.5c-10,0-17.1,2.4-21.2,7c-4,4.6-5.9,11.2-5.9,20v14.4c0,8.8,2,15.6,5.9,20.1c4,4.6,11,6.9,21.2,6.9
		c10.1,0,17-2.3,21.1-6.9c4-4.6,6.1-11.4,6.1-20.1V78.5c0-8.8-1.9-15.4-5.9-20C451,53.8,443.8,51.5,433.8,51.5 M418.4,78.5
		c0-5.7,1-9.9,3.1-12.3c2-2.5,6.2-3.7,12.4-3.7c6.2,0,10.4,1.3,12.4,3.7c2.1,2.4,3.1,6.6,3.1,12.3v14.4c0,5.6-1,9.8-3.1,12.3
		c-2,2.5-6.2,3.7-12.4,3.7c-6.2,0-10.4-1.3-12.4-3.7c-2.1-2.5-3.1-6.7-3.1-12.3V78.5z"/>
            <path fill="#023B59" d="M502.1,84.3l-14.3-8.7c-4.8-2.8-6.1-4.4-6.1-7.4c0-3.4,1.8-5.7,10.8-5.7c5.3,0,9.9,0.7,14.1,2.1
		c1.9,0.5,2.9,0.5,3.6,0c0.8-0.6,1-1.9,1-3.8V59c0-3.5-1-4.4-4.7-5.5c-4.2-1.4-8.9-2-14.1-2c-15.3,0-22.7,5.4-22.7,16.4
		c0,7.1,3.5,12.7,10.3,16.8L494,93c3.5,2.1,5.6,3.7,6.7,4.8c1.1,1.1,1.7,2.6,1.7,4.5c0,4.8-3.4,6.5-12.5,6.5
		c-5.6,0-11.1-0.8-16.1-2.4c-1.7-0.6-2.8-0.6-3.6-0.1c-0.8,0.6-1.2,1.8-1.2,3.9v1.6c0,3.6,0.9,4.3,4.4,5.5
		c4.8,1.5,10.2,2.3,16.3,2.3c16.5,0,24.5-5.7,24.5-17.5C514.3,93.4,509.8,88.9,502.1,84.3"/>
            <path fill="#023B59" d="M531.5,52.4h-1.3c-4,0-5.1,1.1-5.1,5.1v56c0,4,1.2,5.1,5.1,5.1h1.3c4,0,5.1-1.1,5.1-5.1v-56
		C536.6,53.5,535.4,52.4,531.5,52.4"/>
            <path fill="#023B59" d="M596.2,52.4h-1.4c-4,0-5.1,1.1-5.1,5.1v38.9c0,8.6-4.4,12.5-14.4,12.5c-9.9,0-14.4-3.8-14.4-12.5V57.5
		c0-4-1.1-5.1-5.1-5.1h-1.3c-4,0-5.1,1.1-5.1,5.1v38.9c0,15.5,8.7,23.4,25.9,23.4c17,0,26-8.1,26-23.4V57.5
		C601.4,53.5,600.2,52.4,596.2,52.4"/>
            <path fill="#023B59" d="M686.1,113.5L682.6,67c-0.8-10.8-5-15.2-14.3-15.2c-8.5,0-12.6,4.5-14,15.2l-4.7,38.8
		c-0.4,3.2-0.8,3.2-1.6,3.2c-1,0-1.3,0-1.7-3.2L641.6,67c-1.3-10.8-5.4-15.2-14-15.2c-9.2,0-13.4,4.6-14.3,15.2l-3.4,46.5
		c-0.2,2.1,0.1,3.3,0.8,4.1c0.7,0.8,1.9,1.1,4.1,1.1h1.5c4.1,0,5.3-1.2,5.4-5.1l3.1-46.4c0.3-4.1,1.3-5,2.9-5c1.7,0,2.6,0.7,3.1,5
		l5.5,40.4c1.1,8,4.4,11.1,11.6,11.1c7.6,0,10.5-2.7,11.6-11.1l5.4-40.4c0.5-4.6,1.6-5,3-5c1.9,0,2.7,0.7,3,5l3.2,46.4
		c0.3,4,1.5,5.1,5.4,5.1h1.5c2.2,0,3.3-0.3,4.1-1.1C686.1,116.8,686.3,115.6,686.1,113.5"/>
            <path fill="#00A3CC" d="M93.5,26.6c0-4.5-3.6-8.1-8.1-8.1c-4.5,0-8.2,3.6-8.2,8.1c0,4.1,2.9,7.4,6.8,8V55c0,0.7,0.6,1.3,1.4,1.3
		c0.7,0,1.3-0.6,1.3-1.3V34.6C90.5,34,93.5,30.7,93.5,26.6"/>
            <path fill="#00A3CC" d="M77.2,144.1c0,4.5,3.6,8.1,8.1,8.1c4.5,0,8.2-3.6,8.2-8.1c0-4-2.9-7.4-6.8-8v-20.4c0-0.7-0.6-1.3-1.4-1.3
		c-0.7,0-1.3,0.6-1.3,1.3V136C80.1,136.7,77.2,140,77.2,144.1"/>
            <path fill="#00A3CC" d="M144.1,93.5c4.5,0,8.1-3.6,8.1-8.1c0-4.5-3.6-8.2-8.1-8.2c-4.1,0-7.4,2.9-8,6.8h-20.4c-0.7,0-1.3,0.6-1.3,1.4
		c0,0.7,0.6,1.3,1.3,1.3H136C136.7,90.5,140,93.5,144.1,93.5"/>
            <path fill="#00A3CC" d="M26.6,77.2c-4.5,0-8.1,3.6-8.1,8.1c0,4.5,3.6,8.2,8.1,8.1c4,0,7.4-2.9,8-6.8H55c0.7,0,1.3-0.6,1.3-1.4
		c0-0.7-0.6-1.3-1.3-1.3H34.6C34,80.1,30.7,77.2,26.6,77.2"/>
            <path fill="#00A3CC" d="M121.1,132.6c3.2,3.2,8.3,3.2,11.5,0c3.2-3.2,3.2-8.3,0-11.5c-2.9-2.9-7.3-3.2-10.5-0.9l-14.4-14.4
		c-0.5-0.5-1.4-0.5-1.9,0.1c-0.5,0.5-0.5,1.4,0,1.8l14.4,14.4C117.9,125.3,118.2,129.7,121.1,132.6"/>
            <path fill="#00A3CC" d="M49.6,38c-3.2-3.2-8.3-3.2-11.5,0c-3.2,3.2-3.2,8.3,0,11.5c2.9,2.9,7.3,3.2,10.5,0.9l14.4,14.4
		c0.5,0.5,1.4,0.5,1.9-0.1c0.5-0.5,0.5-1.4,0-1.8L50.4,48.6C52.7,45.4,52.4,40.9,49.6,38"/>
            <path fill="#00A3CC" d="M38,121.1c-3.2,3.2-3.2,8.3,0,11.5c3.2,3.2,8.3,3.2,11.5,0c2.9-2.9,3.2-7.3,0.9-10.5l14.4-14.4
		c0.5-0.5,0.5-1.4-0.1-1.9c-0.5-0.5-1.4-0.5-1.8,0l-14.4,14.4C45.4,117.9,40.9,118.2,38,121.1"/>
            <path fill="#00A3CC" d="M132.6,49.6c3.2-3.2,3.2-8.3,0-11.5c-3.2-3.2-8.3-3.2-11.5,0c-2.9,2.9-3.2,7.3-0.9,10.5l-14.4,14.4
		c-0.5,0.5-0.5,1.4,0.1,1.9c0.5,0.5,1.4,0.5,1.8,0l14.4-14.4C125.3,52.7,129.7,52.4,132.6,49.6"/>
            <path fill="#023B59" d="M55.9,13.2c-3-5.1-9.5-6.9-14.6-3.9c-5.1,3-6.9,9.5-3.9,14.6c2.7,4.6,8.2,6.5,13,4.7l13.4,23.2
		c0.5,0.8,1.6,1.1,2.4,0.6c0.8-0.4,1.1-1.6,0.6-2.3L53.4,26.8C57.4,23.5,58.6,17.8,55.9,13.2"/>
            <path fill="#023B59" d="M114.8,157.5c3,5.1,9.5,6.9,14.6,3.9c5.1-3,6.9-9.5,3.9-14.6c-2.7-4.6-8.2-6.5-13-4.7l-13.4-23.2
		c-0.5-0.8-1.6-1.1-2.4-0.6c-0.8,0.4-1.1,1.6-0.6,2.3l13.4,23.2C113.3,147.1,112.1,152.9,114.8,157.5"/>
            <path fill="#023B59" d="M157.5,55.9c5.1-3,6.9-9.5,3.9-14.6c-3-5.1-9.5-6.9-14.6-3.9c-4.6,2.7-6.5,8.2-4.7,13l-23.2,13.4
		c-0.8,0.5-1.1,1.6-0.6,2.4c0.4,0.8,1.6,1.1,2.3,0.6l23.2-13.4C147.1,57.4,152.9,58.6,157.5,55.9"/>
            <path fill="#023B59" d="M13.2,114.8c-5.1,3-6.9,9.5-3.9,14.6c3,5.1,9.5,6.9,14.6,3.9c4.6-2.7,6.5-8.2,4.7-13l23.2-13.4
		c0.8-0.5,1.1-1.6,0.6-2.4c-0.4-0.8-1.6-1.1-2.3-0.6l-23.2,13.4C23.5,113.3,17.8,112.1,13.2,114.8"/>
            <path fill="#023B59" d="M157.2,115.5c5.7,1.5,11.6-1.8,13.1-7.6c1.5-5.7-1.9-11.6-7.6-13.1c-5.1-1.4-10.4,1.2-12.5,5.9l-25.9-6.9
		c-0.9-0.2-1.9,0.4-2.1,1.3c-0.2,0.9,0.4,1.9,1.2,2.1l25.9,6.9C148.8,109.3,152,114.2,157.2,115.5"/>
            <path fill="#023B59" d="M13.5,55.1C7.7,53.6,1.9,57,0.4,62.7S2.2,74.3,8,75.8c5.1,1.4,10.4-1.2,12.5-5.9l25.9,6.9
		c0.9,0.2,1.9-0.4,2.1-1.3c0.2-0.9-0.4-1.9-1.2-2.1l-25.9-6.9C21.9,61.4,18.6,56.5,13.5,55.1"/>
            <path fill="#023B59" d="M55.1,157.2c-1.5,5.7,1.8,11.6,7.6,13.1c5.7,1.5,11.6-1.9,13.1-7.6c1.4-5.1-1.2-10.4-5.9-12.5l6.9-25.9
		c0.2-0.9-0.4-1.9-1.3-2.1c-0.9-0.2-1.8,0.4-2.1,1.2l-6.9,25.9C61.4,148.8,56.5,152,55.1,157.2"/>
            <path fill="#023B59" d="M115.5,13.5c1.5-5.7-1.8-11.6-7.6-13.1C102.2-1.2,96.4,2.2,94.8,8c-1.4,5.1,1.2,10.4,5.9,12.5l-6.9,25.9
		c-0.2,0.9,0.4,1.9,1.3,2.1c0.9,0.2,1.9-0.4,2.1-1.2l6.9-25.9C109.3,21.9,114.2,18.6,115.5,13.5"/>
            <path fill="#A2D8E6" d="M80.5,45.7c-0.8-2.9-3.8-4.7-6.7-3.9c-2.9,0.8-4.7,3.8-3.9,6.7c0.7,2.6,3.2,4.3,5.8,4l3.5,13.2
		c0.1,0.5,0.6,0.7,1.1,0.6c0.4-0.1,0.7-0.6,0.6-1.1l-3.5-13.2C79.8,51.1,81.2,48.4,80.5,45.7"/>
            <path fill="#A2D8E6" d="M90.2,124.9c0.8,2.9,3.8,4.7,6.7,3.9c2.9-0.8,4.7-3.8,3.9-6.7c-0.7-2.6-3.2-4.3-5.8-4l-3.5-13.2
		c-0.1-0.5-0.6-0.7-1.1-0.6c-0.4,0.1-0.7,0.6-0.6,1.1l3.5,13.2C90.8,119.6,89.5,122.3,90.2,124.9"/>
            <path fill="#A2D8E6" d="M124.9,80.5c2.9-0.8,4.7-3.8,3.9-6.7c-0.8-2.9-3.8-4.7-6.7-3.9c-2.6,0.7-4.3,3.2-4,5.8l-13.2,3.5
		c-0.5,0.1-0.7,0.6-0.6,1.1c0.1,0.4,0.6,0.7,1.1,0.6l13.2-3.5C119.6,79.8,122.3,81.2,124.9,80.5"/>
            <path fill="#A2D8E6" d="M45.7,90.2c-2.9,0.8-4.7,3.8-3.9,6.7c0.8,2.9,3.8,4.7,6.7,3.9c2.6-0.7,4.3-3.2,4-5.8l13.2-3.5
		c0.5-0.1,0.7-0.6,0.6-1.1c-0.1-0.4-0.6-0.7-1.1-0.6l-13.2,3.5C51.1,90.8,48.4,89.5,45.7,90.2"/>
            <path fill="#A2D8E6" d="M116.8,109.9c2.6,1.5,6,0.6,7.5-2c1.5-2.6,0.6-6-2-7.5c-2.4-1.4-5.3-0.8-7,1.2l-11.9-6.9
		c-0.4-0.2-1-0.1-1.2,0.4c-0.2,0.4-0.1,1,0.3,1.2l11.8,6.9C113.4,105.7,114.4,108.5,116.8,109.9"/>
            <path fill="#A2D8E6" d="M53.9,60.8c-2.6-1.5-6-0.6-7.5,2c-1.5,2.6-0.6,6,2,7.5c2.4,1.4,5.3,0.8,7-1.2l11.9,6.9c0.4,0.2,1,0.1,1.2-0.4
		c0.2-0.4,0.1-1-0.3-1.2l-11.8-6.9C57.2,65,56.3,62.1,53.9,60.8"/>
            <path fill="#A2D8E6" d="M60.8,116.8c-1.5,2.6-0.6,6,2,7.5c2.6,1.5,6,0.6,7.5-2c1.4-2.4,0.8-5.3-1.2-7l6.9-11.9c0.2-0.4,0.1-1-0.4-1.2
		c-0.4-0.2-1-0.1-1.2,0.3l-6.9,11.8C65,113.4,62.1,114.4,60.8,116.8"/>
            <path fill="#A2D8E6" d="M109.9,53.9c1.5-2.6,0.6-6-2-7.5c-2.6-1.5-6-0.6-7.5,2c-1.4,2.4-0.8,5.3,1.2,7l-6.9,11.9
		c-0.2,0.4-0.1,1,0.4,1.2c0.4,0.2,1,0.1,1.2-0.3l6.9-11.8C105.7,57.2,108.5,56.3,109.9,53.9"/>
            <path fill="#023B59" d="M205,139.2h1.8l5,25.9h-2l-1.3-7.2h-15.2l-5.2,7.2h-2.1L205,139.2z M208.3,156.2l-2.8-14.9l-10.8,14.9H208.3z"
            />
            <path fill="#023B59" d="M217.5,155.9L217.5,155.9c0-6.2,4.6-9.9,9.1-9.9c3.8,0,6.2,2.1,7.7,4.5v-12.1h1.8v26.9h-1.8V161
		c-1.6,2.5-4,4.6-7.7,4.6C222.1,165.6,217.5,162,217.5,155.9 M234.4,155.8L234.4,155.8c0-4.9-3.8-8.1-7.7-8.1c-4.1,0-7.4,3-7.4,8
		v0.1c0,4.9,3.4,8.1,7.4,8.1C230.7,163.9,234.4,160.6,234.4,155.8"/>
            <polygon fill="#023B59" points="241,146.4 243.1,146.4 250.2,163.1 257.4,146.4 259.4,146.4 251,165.3 249.5,165.3 	" />
            <path fill="#023B59" d="M261.9,159.7L261.9,159.7c0-4,3.3-6.2,8.2-6.2c2.6,0,4.4,0.3,6.2,0.8v-0.8c0-3.8-2.3-5.7-6.2-5.7
		c-2.3,0-4.2,0.6-5.9,1.5l-0.7-1.6c2.1-1,4.1-1.6,6.7-1.6c2.5,0,4.6,0.7,5.9,2.1c1.3,1.3,1.9,3,1.9,5.3v11.7h-1.8V162
		c-1.3,1.8-3.6,3.6-7.2,3.6C265.7,165.6,261.9,163.6,261.9,159.7 M276.4,158.1V156c-1.6-0.4-3.7-0.8-6.4-0.8c-4,0-6.2,1.8-6.2,4.4
		v0.1c0,2.7,2.6,4.3,5.4,4.3C273.1,163.9,276.4,161.6,276.4,158.1"/>
            <path fill="#023B59" d="M284.6,146.4h1.8v3.5c1.3-2.2,3.3-3.9,6.8-3.9c4.7,0,7.5,3.2,7.5,7.7v11.5h-1.8V154c0-3.9-2.2-6.4-6-6.4
		c-3.7,0-6.5,2.8-6.5,6.7v10.9h-1.8V146.4z"/>
            <path fill="#023B59" d="M305.8,155.9L305.8,155.9c0-5.3,4.2-9.9,9.6-9.9c3.5,0,5.7,1.6,7.5,3.5l-1.3,1.3c-1.6-1.7-3.5-3.1-6.3-3.1
		c-4.3,0-7.6,3.6-7.6,8.1v0.1c0,4.5,3.4,8.1,7.7,8.1c2.6,0,4.7-1.4,6.3-3.1l1.3,1.1c-1.9,2.2-4.2,3.7-7.7,3.7
		C310,165.6,305.8,161.1,305.8,155.9"/>
            <path fill="#023B59" d="M326.3,155.8L326.3,155.8c0-5.5,3.8-9.9,8.9-9.9c5.3,0,8.6,4.3,8.6,9.8c0,0.3,0,0.4,0,0.7h-15.6
		c0.3,4.7,3.7,7.4,7.4,7.4c2.9,0,4.9-1.3,6.4-2.9l1.3,1.1c-1.9,2.1-4.2,3.5-7.8,3.5C330.6,165.6,326.3,161.7,326.3,155.8
		 M341.9,154.9c-0.3-3.8-2.4-7.3-6.7-7.3c-3.7,0-6.6,3.1-6.9,7.3H341.9z"/>
            <path fill="#023B59" d="M347.9,155.9L347.9,155.9c0-6.2,4.6-9.9,9.1-9.9c3.8,0,6.2,2.1,7.7,4.5v-12.1h1.8v26.9h-1.8V161
		c-1.6,2.5-4,4.6-7.7,4.6C352.5,165.6,347.9,162,347.9,155.9 M364.8,155.8L364.8,155.8c0-4.9-3.8-8.1-7.7-8.1c-4.1,0-7.4,3-7.4,8
		v0.1c0,4.9,3.4,8.1,7.4,8.1C361.1,163.9,364.8,160.6,364.8,155.8"/>
            <polygon fill="#00A5CE" points="384.8,139.4 386.7,139.4 386.7,151.3 403.2,151.3 403.2,139.4 405.1,139.4 405.1,165.2 403.2,165.2 
		403.2,153.1 386.7,153.1 386.7,165.2 384.8,165.2 	"/>
            <path fill="#00A5CE" d="M411.1,155.8L411.1,155.8c0-5.5,3.8-9.9,8.9-9.9c5.3,0,8.6,4.3,8.6,9.8c0,0.3,0,0.4,0,0.7H413
		c0.3,4.7,3.7,7.4,7.4,7.4c2.9,0,4.9-1.3,6.4-2.9l1.3,1.1c-1.9,2.1-4.2,3.5-7.8,3.5C415.3,165.6,411.1,161.7,411.1,155.8
		 M426.6,154.9c-0.3-3.8-2.4-7.3-6.7-7.3c-3.7,0-6.6,3.1-6.9,7.3H426.6z"/>
            <path fill="#00A5CE" d="M432.3,159.7L432.3,159.7c0-4,3.3-6.2,8.2-6.2c2.6,0,4.4,0.3,6.2,0.8v-0.8c0-3.8-2.3-5.7-6.2-5.7
		c-2.3,0-4.2,0.6-5.9,1.5l-0.7-1.6c2.1-1,4.1-1.6,6.7-1.6c2.5,0,4.6,0.7,5.9,2.1c1.3,1.3,1.9,3,1.9,5.3v11.7h-1.8V162
		c-1.3,1.8-3.6,3.6-7.2,3.6C436.1,165.6,432.3,163.6,432.3,159.7 M446.8,158.1V156c-1.6-0.4-3.7-0.8-6.4-0.8c-4,0-6.2,1.8-6.2,4.4
		v0.1c0,2.7,2.6,4.3,5.4,4.3C443.5,163.9,446.8,161.6,446.8,158.1"/>
            <rect x="455.2" y="138.3" fill="#00A5CE" width="1.8" height="26.9" />
            <path fill="#00A5CE" d="M465.1,160.4v-12.3h-2.7v-1.7h2.7v-6h1.8v6h6.6v1.7h-6.6v12.1c0,2.7,1.6,3.6,3.8,3.6c0.9,0,1.7-0.2,2.8-0.7
		v1.7c-1,0.4-1.9,0.7-3.1,0.7C467.5,165.5,465.1,163.9,465.1,160.4"/>
            <path fill="#00A5CE" d="M478.8,138.3h1.8v11.6c1.3-2.2,3.3-3.9,6.8-3.9c4.7,0,7.5,3.2,7.5,7.7v11.5h-1.8V154c0-3.9-2.2-6.4-6-6.4
		c-3.7,0-6.5,2.8-6.5,6.7v10.9h-1.8V138.3z"/>
            <path fill="#023B59" d="M500.1,155.9L500.1,155.9c0-5.3,4.2-9.9,9.6-9.9c3.5,0,5.7,1.6,7.5,3.5l-1.3,1.3c-1.6-1.7-3.5-3.1-6.3-3.1
		c-4.3,0-7.6,3.6-7.6,8.1v0.1c0,4.5,3.4,8.1,7.7,8.1c2.6,0,4.7-1.4,6.3-3.1l1.3,1.1c-1.9,2.2-4.2,3.7-7.7,3.7
		C504.3,165.6,500.1,161.1,500.1,155.9"/>
            <path fill="#023B59" d="M520.9,159.7L520.9,159.7c0-4,3.3-6.2,8.2-6.2c2.6,0,4.4,0.3,6.2,0.8v-0.8c0-3.8-2.3-5.7-6.2-5.7
		c-2.3,0-4.2,0.6-5.9,1.5l-0.7-1.6c2.1-1,4.1-1.6,6.7-1.6c2.5,0,4.6,0.7,5.9,2.1c1.3,1.3,1.9,3,1.9,5.3v11.7h-1.8V162
		c-1.3,1.8-3.6,3.6-7.2,3.6C524.6,165.6,520.9,163.6,520.9,159.7 M535.3,158.1V156c-1.6-0.4-3.7-0.8-6.4-0.8c-4,0-6.2,1.8-6.2,4.4
		v0.1c0,2.7,2.6,4.3,5.4,4.3C532,163.9,535.3,161.6,535.3,158.1"/>
            <path fill="#023B59" d="M543.5,146.4h1.8v5.3c1.5-3.3,4.6-5.8,8.3-5.6v2h-0.2c-4.3,0-8.1,3.3-8.1,9.4v7.7h-1.8V146.4z" />
            <path fill="#023B59" d="M556,155.8L556,155.8c0-5.5,3.8-9.9,8.9-9.9c5.3,0,8.6,4.3,8.6,9.8c0,0.3,0,0.4,0,0.7H558
		c0.3,4.7,3.7,7.4,7.4,7.4c2.9,0,4.9-1.3,6.4-2.9l1.3,1.1c-1.9,2.1-4.2,3.5-7.8,3.5C560.3,165.6,556,161.7,556,155.8 M571.6,154.9
		c-0.3-3.8-2.4-7.3-6.7-7.3c-3.7,0-6.6,3.1-6.9,7.3H571.6z"/>
        </SvgIcon>
    );
};

export default Logo;