import React from "react";
import { Tooltip, styled, TooltipProps, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 180,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
    borderRadius: '4px',
    padding: '6px 10px',
    margin: '0px !important',
    textAlign: 'center'
  },
}));

const Tooltipz = (props: any) => {
  return (
    <CustomTooltip
      title={props.title}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      arrow
    >
      {props.children}
    </CustomTooltip>
  )
};

export default Tooltipz;