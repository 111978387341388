import { Shortcut } from '../models/Shortcuts';
import { Trends } from '../models/Trends';
import { User, UserNotifications, Users } from '../models/User';
import { Get, Post, Delete, Put } from '../utils/axios';

export function getUsers(
    querySearch?: string,
    querySkip?: number,
    queryTake?: number,
    filters?: any[]
): Promise<Users> {
    let query = ``;
    let takenQ = 0;

    if (querySearch || querySkip || queryTake) {
        if (querySearch) {
            query += "?";
            query += `q=${querySearch}`;
            ++takenQ;
        }
        if (querySkip !== undefined && querySkip >= 0) {
            query += takenQ > 0 ? "&" : "?";
            query += `skip=${querySkip}`;
            ++takenQ;
        }

        if (queryTake !== undefined && queryTake > 0) {
            query += takenQ > 0 ? "&" : "?";
            query += `take=${queryTake}`;
            ++takenQ;
        }
    }

    if (filters && filters.length > 0) {
        filters.forEach(
            (f) => {
                query += `${(takenQ > 0 ? "&" : "?")}${f.key}=${f.value}`;
                ++takenQ;
            }
        )
    }

    return new Promise((resolve, reject) => {
        Get<Users>(`/api/users${query}`)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data);
                }

                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function getUser(userEmail: string): Promise<User> {
    return new Promise((resolve, reject) => {
        Get<User>(`/api/users/${userEmail}`)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data);
                }

                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function getUserNotifications(userEmail: string): Promise<UserNotifications[]> {
    return new Promise((resolve, reject) => {
        Get<User>(`/api/users/notifications/${userEmail}?skip=0&take=5`)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data.notifications);
                }
                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function createUser(userData: any): Promise<User> {

    return new Promise((resolve, reject) => {
        Post<User>("/api/users", userData).then((response: any) => {
            if (response.status === 200) {
                resolve(response.data);
            }

            reject(response.data);
        })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function deleteUser(userEmail: string) {
    return Delete(`/api/users/${userEmail}`);
}

export function updateUser(userData: User): Promise<User> {

    return new Promise((resolve, reject) => {
        Put<User>(`/api/users/${userData.email}`, userData).then((response: any) => {
            if (response.status === 200) {
                resolve(response.data);
            }

            reject(response.data);
        })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function updateSelfUser(userData: User): Promise<User> {

    return new Promise((resolve, reject) => {
        Put<User>("/api/users", userData).then((response: any) => {
            if (response.status === 200) {
                resolve(response.data);
            }

            reject(response.data);
        })
            .catch((error: any) => {
                reject(error);
            });
    });

}

export function uploadProfileImage(formData: FormData): Promise<User> {

    return new Promise((resolve, reject) => {

        Put<User>("/api/users/photo", formData).then((response: any) => {
            if (response.status === 200) {
                resolve(response.data);
            }

            reject(response.data);
        })
            .catch((error: any) => {
                reject(error);
            });
    });

}

/*  
*   USER SHORTCUTS
*/

export function addUserShortcuts(sh: Shortcut): Promise<Shortcut[]> {

    return new Promise((resolve, reject) => {
        Post<Shortcut[]>("/api/users/shortcuts", sh)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data);
                }

                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function getUserShortcuts(): Promise<Shortcut[]> {

    return new Promise((resolve, reject) => {
        Get<Shortcut[]>(`/api/users/shortcuts`)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data);
                }

                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}


export function deleteUserShortcut(shId: string) {
    return Delete(`/api/users/shortcuts/${shId}`);
}

/*
* STATICTIS
*/

export function getProductsStats(): Promise<Trends[]> {

    return new Promise((resolve, reject) => {
        Get<Trends[]>(`/api/statistics/products`)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data);
                }

                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};

export function getDrugsStats(): Promise<Trends[]> {

    return new Promise((resolve, reject) => {
        Get<Trends[]>(`/api/statistics/drugs`)
            .then((response: any) => {
                if (response.status === 200) {
                    resolve(response.data);
                }

                reject(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};
