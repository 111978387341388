export const customWhite = {
  50: '#FFFFFF',
  100: '#FAFAFA',
  200: '#CCCCCC',
  300: '#AAAAAA'
}

export const customBlack = {
  50: '#000000',
  100: '#101616'
}

export const customBlue = {
  50: "#00A3CA",
  100: "#003A5A",
};


export const customSuccess = {
  50: '#5cb85c',
  100: '#9dd49d'
};

export const customWarning = {
  50: '#fff4e5',
  100: '#edb95e'
};

export const customError = {
  50: '#b00020',
  100: '#cf6679'
};

export const customDetailsColors = {
  mainContent: '#00C0B5',
  mainHover: '#009990',
  dciContent: "#246EA7",
  dciBg: "#D5E7F5"
};

export const customSelection = {
  highlight: "#FEDD00", // Yellow C - rgb(254, 221, 0)
  warning: "#ed6c02"//"#ffc107",
}
