import React from "react";
import { SvgIcon } from "@mui/material";

const LabIcon = (props: any) => {
    if (props.fill) {
        return (
            <SvgIcon {...props} viewBox="0 0 115.8 173.1">
                <path d="m17 156c10.5 10.5 25 17 40.9 17 16 0 30.5-6.5 41-17s17-25 17-40.9v-0.6c-0.1-12.5-4.3-24.1-11.1-33.5-6.7-9.1-15.9-16.1-26.6-20.1v-43.8h0.2c2.4 0 4.5-1 6.1-2.5s2.5-3.7 2.5-6.1-1-4.5-2.5-6.1c-1.6-1.5-3.8-2.5-6.2-2.5h-40.8c-2.4 0-4.5 1-6.1 2.5-1.5 1.5-2.5 3.7-2.5 6.1s1 4.5 2.5 6.1c1.5 1.5 3.7 2.5 6.1 2.5h0.2v43.8c-10.3 3.8-19.2 10.4-25.7 19-6.9 8.9-11.2 19.8-11.9 31.7-0.1 1.2-0.1 2.3-0.1 3.5 0 16 6.5 30.5 17 40.9zm17.2-151c-22.8-3.47-11.4-1.73 0 0zm-19.1 77.2c6.4-8.3 15.1-14.7 25.2-18.2 0.8-0.3 1.3-1 1.3-1.9v-45.1h32.6v45.2c0 0.8 0.5 1.6 1.3 1.9 10.5 3.6 19.5 10.3 26 19.1 5.9 8 9.6 17.8 10.3 28.3-17.3-6.2-36.3-8.1-53.5-8.1-25 0-46.4 4.1-53.9 5.7 1-10.1 4.9-19.3 10.7-26.9z" />
                <path d="m35.6 83.8c-1.8 0-3.5 0.7-4.7 2-1.2 1.2-2 2.9-2 4.7s0.8 3.5 2 4.7 2.9 1.9 4.7 1.9 3.5-0.7 4.7-1.9 2-2.9 2-4.7-0.7-3.5-2-4.7c-1.2-1.2-2.9-2-4.7-2z" />
                <path d="m64.6 54.5c0-1.8-0.8-3.5-2-4.7s-2.9-2-4.7-2-3.5 0.7-4.7 2c-1.2 1.2-2 2.9-2 4.7s0.8 3.5 2 4.7 2.9 2 4.7 2 3.5-0.7 4.7-2c1.2-1.2 2-2.9 2-4.7z" />

            </SvgIcon>
        );
    }

    return (
        <SvgIcon {...props} viewBox="0 0 115.8 173.1">
            <path d="M17,156.1c10.5,10.5,25,17,40.9,17c16,0,30.5-6.5,41-17c10.5-10.5,17-25,17-40.9c0-0.2,0-0.4,0-0.6v0v0
                c0,0,0,0,0,0c-0.1-12.5-4.3-24.1-11.1-33.5C98.1,72,88.9,65,78.2,61V17.2h0.2c2.4,0,4.5-1,6.1-2.5c1.6-1.5,2.5-3.7,2.5-6.1
                c0-2.4-1-4.5-2.5-6.1C82.9,1,80.7,0,78.3,0H37.5c-2.4,0-4.5,1-6.1,2.5c-1.5,1.5-2.5,3.7-2.5,6.1c0,2.4,1,4.5,2.5,6.1
                c1.5,1.5,3.7,2.5,6.1,2.5h0.2V61C27.4,64.8,18.5,71.4,12,80C5.1,88.9,0.8,99.8,0.1,111.7v0C0,112.9,0,114,0,115.2
                C0,131.2,6.5,145.7,17,156.1z M96,153.3c-9.8,9.8-23.2,15.8-38.1,15.8c-14.9,0-28.4-6-38.1-15.8C10,143.6,4,130.1,4,115.2
                c0-0.6,0-1.2,0-1.8c5.9-1.3,28.2-5.9,54.2-5.9c17.5,0,36.6,2.1,53.6,8.5C111.6,130.6,105.6,143.7,96,153.3z M37.5,13.2
                c-1.3,0-2.4-0.5-3.3-1.4c-0.8-0.8-1.3-2-1.3-3.3c0-1.3,0.5-2.4,1.3-3.3c0.8-0.8,2-1.3,3.3-1.3h40.9c1.3,0,2.4,0.5,3.3,1.3
                c0.8,0.8,1.3,2,1.3,3.3c0,1.3-0.5,2.4-1.3,3.3c-0.8,0.8-2,1.4-3.3,1.4H37.5z M15.1,82.4c6.4-8.3,15.1-14.7,25.2-18.2
                c0.8-0.3,1.3-1,1.3-1.9V17.2h32.6v45.2c0,0.8,0.5,1.6,1.3,1.9C86,67.9,95,74.6,101.5,83.4c5.9,8,9.6,17.8,10.3,28.3
                c-17.3-6.2-36.3-8.1-53.5-8.1c-25,0-46.4,4.1-53.9,5.7C5.4,99.2,9.3,90,15.1,82.4z"/>
            <path d="M35.6,83.8c-1.8,0-3.5,0.7-4.7,2c-1.2,1.2-2,2.9-2,4.7c0,1.8,0.8,3.5,2,4.7c1.2,1.2,2.9,1.9,4.7,1.9
                s3.5-0.7,4.7-1.9c1.2-1.2,2-2.9,2-4.7s-0.7-3.5-2-4.7C39.1,84.6,37.4,83.8,35.6,83.8z M37.5,92.4c-0.5,0.5-1.2,0.8-1.9,0.8
                c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9c0-0.7,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8c0.8,0,1.4,0.3,1.9,0.8
                c0.5,0.5,0.8,1.2,0.8,1.9C38.3,91.2,38,91.9,37.5,92.4z"/>
            <path d="M64.6,54.5c0-1.8-0.8-3.5-2-4.7c-1.2-1.2-2.9-2-4.7-2c-1.8,0-3.5,0.7-4.7,2c-1.2,1.2-2,2.9-2,4.7
                c0,1.8,0.8,3.5,2,4.7c1.2,1.2,2.9,2,4.7,2c1.8,0,3.5-0.7,4.7-2C63.8,58,64.6,56.3,64.6,54.5z M59.8,56.4c-0.5,0.5-1.2,0.8-1.9,0.8
                c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9c0-0.7,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8c0.7,0,1.4,0.3,1.9,0.8
                s0.8,1.2,0.8,1.9C60.6,55.3,60.3,55.9,59.8,56.4z"/>
        </SvgIcon>
    );

}

export default LabIcon;