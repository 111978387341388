import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Avatar,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItemButton,
  ListItemText,
  Typography,
  DrawerProps,
  Theme,
  useTheme,
} from "@mui/material";
import AppContext from "../context/AppContext";
import Role from "./Role";
import { Role as UserRole } from "../models/Role";
import styled from "@emotion/styled/macro";
import { adminRoutes } from "../routes/index";
import { CLEAR_CACHE_QUERY, PROFILE_PATH } from "../constants/routes";
import LogoW from "./icons/LogoW";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { convertToRGA } from "../utils/utils";
import { customSelection } from "../theme/simposium/colors";

const Drawer = styled(MuiDrawer)`
  border: 0;
  > div {
    border: 0;
  }
` as React.ComponentType<DrawerProps>;

const Brand = styled.div`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.sidebar.header.background};
  padding: ${({ theme }: { theme: Theme }) => theme.spacing(10)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg,
  img {
    max-width: 175px;
    width: 100%;
    height: auto;
  }
  svg {
    transition: all 0.4s ease-in;
    filter: drop-shadow(0px 2px 3px rgb(0 0 0 / 0.2));
  }
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xl")} {
    padding: 25px;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.sidebar.background};
  transition: none !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const List = styled(MuiList)``;

const Items = styled.div`
  display: block;
  @keyframes pulsate {
    100% {
      background: ${convertToRGA(customSelection.highlight, 0.08)};
    }
    0% {
      background: ${convertToRGA(customSelection.highlight, 0)};
    }
  }
  @keyframes pulsateIcon {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
`;

const Category = styled(ListItemButton)`
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }: { theme: Theme }) => theme.spacing(2)} 0px ${({
  theme,
}: {
  theme: Theme;
}) => theme.spacing(2)} ${({ theme }: { theme: Theme }) => theme.spacing(10)};
  @keyframes pulsateColor {
    100% {
      color: ${customSelection.highlight};
    }
    0% {
      color: ${({ theme }: { theme: Theme }) =>
        theme.palette.secondary.contrastText};
    }
  }
  svg {
    color: ${({ theme }: { theme: Theme }) => theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(2.5)};
  }
  &.new {
    animation: pulsate 2.5s infinite alternate;
    & .new-icon {
      position: absolute;
      top: 50%;
      left: 3px;
      width: 18px;
      height: auto;
      color: ${customSelection.highlight};
      transform: translateY(-50%);
      animation: pulsateIcon 2.5s infinite alternate;
    }
    & > svg:not(.new-icon),
    & > div > span {
      animation: pulsateColor 2.5s infinite alternate;
    }
    &.active {
      animation: none !important;
      & .new-icon {
        animation: none !important;
        opacity: 0;
      }
      & > svg:not(.new-icon),
      & > div > span {
        animation: none !important;
      }
    }
  }
  &.active {
    background: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    span {
      font-weight: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontWeightMedium};
    }
  }
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xl")} {
    padding: ${({ theme }: { theme: Theme }) => theme.spacing(2)} 0px ${({
  theme,
}: {
  theme: Theme;
}) => theme.spacing(2)} 25px}; 
  }
`;

const AdminMenu = styled.div`
  display: block;
  padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(10)};
  & > span {
    display: block;
    font-weight: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontWeightMedium};
    color: ${({ theme }: { theme: Theme }) => theme.palette.text.disabled};
    text-align: center;
    text-transform: uppercase;
  }
  a {
    svg,
    span {
      color: ${({ theme }: { theme: Theme }) => theme.palette.text.disabled};
    }
    &.active {
      svg,
      span {
        color: ${({ theme }: { theme: Theme }) => theme.sidebar.color};
      }
    }
  }
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xl")} {
    padding-top: 25px;
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${({ theme }: { theme: Theme }) => theme.sidebar.footer.color};
    font-size: ${({ theme }: { theme: Theme }) =>
      theme.typography.body1.fontSize};
    font-weight: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontWeightLight};
    padding: 0;
    line-height: 1;
  }
`;

const SidebarFooter = styled.div`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.sidebar.footer.background} !important;
  padding: ${({ theme }: { theme: Theme }) => theme.spacing(10)};
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xl")} {
    padding: 20px 25px;
  }
`;

const SidebarFooterText = styled(Typography)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }: { theme: Theme }) =>
    theme.typography.fontWeightMedium};
  font-size: 100%;
  line-height: 1.2;
  transform: translateY(9.6px);
  transition: all 0.6s ease;
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${({ theme }: { theme: Theme }) => theme.sidebar.footer.color};
  font-size: 100%;
  font-weight: ${({ theme }: { theme: Theme }) =>
    theme.typography.fontWeightLight};
  line-height: 1.2;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.6s, opacity 0.6s linear;
  & > a {
    text-decoration: none;
    color: inherit;
    font-weight: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontWeightLight};
  }
`;

const SideBarFooterGrid = styled(Grid)`
  align-self: center;
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing(2.5)};
  &:hover > p {
    transform: translateY(0px);
  }
  &:hover > span {
    visibility: visible;
    opacity: 1;
  }
`;

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  isHighlighted,
  ...rest
}: any) => {
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  return (
    <Category {...rest} className={Boolean(isHighlighted) ? "new" : ""}>
      {Boolean(isHighlighted) && <FiberNewIcon className="new-icon" />}
      {icon}
      <CategoryText theme={theme}>{t(name)}</CategoryText>
    </Category>
  );
};

const Sidebar = ({
  classes,
  staticContext,
  location,
  history,
  match,
  routes,
  ...rest
}: any) => {
  const theme = useTheme();
  const appContext = React.useContext(AppContext);
  const { t }: { t: any } = useTranslation();
  const user = appContext.user;

  let userName: string = `${user?.firstName} ${user?.lastName}`;

  if (user && userName.length >= 16) {
    if (user.lastName.length >= 13) {
      userName = `${user.firstName.charAt(0)}. `;
      user.lastName.split(" ").forEach((lN: string, index: number) => {
        if (index + 1 === user.lastName.split(" ").length) {
          userName += lN;
        } else if (lN.length <= 3) {
          userName += `${lN} `;
        } else {
          userName += `${lN.charAt(0)}. `;
        }
      });
    } else {
      userName = `${user.firstName.charAt(0)}. ${user.lastName}`;
    }
  }

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand theme={theme}>
        {user?.entity?.theme?.logoUrl ? (
          <img src={user.entity?.theme.logoUrl} alt={user?.entity?.name} />
        ) : (
          <LogoW />
        )}
      </Brand>
      {!appContext.loading && (
        <Scrollbar theme={theme}>
          <List theme={theme} disablePadding>
            <Items theme={theme}>
              {routes.map((category: any, index: number) => (
                <React.Fragment key={index}>
                  {category.icon ? (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.header}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      badge={category.badge}
                      isHighlighted={category.isHighlighted}
                    />
                  ) : null}
                </React.Fragment>
              ))}
            </Items>
          </List>
          {user?.role === UserRole.Admin && (
            <AdminMenu theme={theme}>
              <Typography variant="caption">{t("Administration")}</Typography>
              <List theme={theme} disablePadding>
                <Items theme={theme}>
                  {adminRoutes.map((category: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {category.icon ? (
                          <SidebarCategory
                            isCollapsable={false}
                            name={category.header}
                            to={category.path}
                            activeClassName="active"
                            component={NavLink}
                            icon={category.icon}
                            badge={category.badge}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Items>
              </List>
            </AdminMenu>
          )}
        </Scrollbar>
      )}
      <SidebarFooter theme={theme}>
        <Grid container>
          <Grid item>
            <Avatar
              alt={user?.firstName + " " + user?.lastName}
              src={
                user?.photoUrl
                  ? `${user?.photoUrl}?${CLEAR_CACHE_QUERY}=${Math.floor(Date.now() / 1000)}`
                  : "/static/img/no_avatar.webp"
              }
              sx={{
                width: 60,
                height: 60,
              }}
            />
          </Grid>
          <SideBarFooterGrid item theme={theme}>
            <SidebarFooterText theme={theme} variant="body2">
              {userName}
            </SidebarFooterText>
            <SidebarFooterSubText theme={theme} variant="caption">
              {user?.role === UserRole.Admin ? (
                <Role role={user?.role} />
              ) : (
                <NavLink to={PROFILE_PATH} exact>
                  {t("View Profile")}
                </NavLink>
              )}
            </SidebarFooterSubText>
          </SideBarFooterGrid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
