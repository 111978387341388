import React from "react";
import { HOME_PATH, SIGN_IN_PATH } from "../constants/routes";
import AppContext from "../context/AppContext";
import { Role } from "../models/Role";
import Loader from "./Loader";
import { Redirect } from "react-router-dom";

// For routes that can only be accessed by admin users
function AdminAuthGuard(props: any) {

  const { children } = props;
  const appContext = React.useContext(AppContext);
  const { user } = appContext;

  if (!appContext.isAuthenticated && !appContext.loading) {
    return <Redirect to={SIGN_IN_PATH} />;
  } else if (appContext.loading) {
    return <Loader />;
  } else if ( user?.role !== Role.Admin ) {
    return <Redirect to={HOME_PATH} />;
  }

  return children;
}

export default AdminAuthGuard;
