import React from "react";
import {
  Box,
  Theme,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled/macro";
import LogoW from "../icons/LogoW";
import Logo from "../icons/Logo";

const Wrapper = styled(Box)`
  max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.values.lg}px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  & > svg {
    max-width: 350px;
    height: auto;
    transition: all 0.4s ease-in;
    filter: drop-shadow(0px 2px 3px rgb(0 0 0 / 0.2));
  }
  @media print {
    display: none;
  }
`;

const Header = (props: { isToken?: boolean, isWhiteVersion?: boolean }) => {

  const theme = useTheme();

  return (
    <Wrapper
      theme={theme}
      sx={{
        padding: props.isToken ? `20px ${theme.spacing(10)}` : 10,
        "& > svg": {
          width: props.isToken ? "180px" : "300px",
        },
        [theme.breakpoints.down("xs")]: {
          "& > svg": {
            width: props.isToken ? "150px" : "250px",
          }
        }
      }}
    >
      {
        props.isWhiteVersion ? <LogoW /> : <Logo />
      }
    </Wrapper>
  );

};

export default Header;