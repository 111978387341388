import React from "react";
import { SvgIcon } from "@mui/material";

const CitricIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 85.3 85.3">
      <path
        d="M2.9,67.8c17.9,17.9,46.9,17.9,64.8,0c17.9-17.9,17.9-46.9,0-64.8L2.9,67.8z M70.7,0c19.5,19.5,19.5,51.2,0,70.7
	C51.2,90.2,19.5,90.2,0,70.7L70.7,0z"
      />
      <path
        d="M39.2,36.1c0.1,0.2,0.2,0.4,0.4,0.6c-0.4,0.1-0.5,0.4-0.7,0.9c-0.1,0.3-0.2,0.7-0.2,1.1l28.5,26.7c0.3-0.1,0.7-0.3,1-0.7
	c6.2-7.5,10-16.2,10.7-25.3c0-0.6-0.2-1.1-0.4-1.4L39.2,36.1z M56.7,46.5l-7.8-2.8c-0.4-0.1-0.7-0.3-0.9-0.6c-0.5-0.5-0.6-1.3-0.4-2
	c0.4-1.1,1.6-1.6,2.7-1.3l7.8,2.8c1.1,0.4,1.6,1.6,1.3,2.7C58.9,46.3,57.7,46.9,56.7,46.5z M37,35.9l1.5-1.5c0.1,0,0.1,0,0.2,0
	c0,0.4,0.1,0.8,0.3,1.2c0,0.1,0.1,0.2,0.1,0.2L78.7,35c0.1-0.3,0.2-0.7,0.2-1.3c-0.3-10-4.7-19.7-12.4-27.4L37,35.9L37,35.9z
	 M50,28.2l7.6-3.4c1.1-0.5,2.3,0,2.8,1.1c0.5,1.1,0,2.3-1.1,2.8L51.7,32c-1.1,0.5-2.3,0-2.8-1.1C48.5,29.9,49,28.7,50,28.2z
	 M35.6,38.9C35.6,38.9,35.6,38.9,35.6,38.9c-0.4-0.3-0.8-0.4-1.2-0.4l1.3-1.3l0,0L6.4,66.5C14,74,23.7,77.9,33.6,78.9
	c0.5,0.1,0.9,0,1.2-0.1L35.6,38.9z M25.5,60.1c-1.1-0.5-1.5-1.7-1.1-2.8l3.4-7.6c0.5-1.1,1.7-1.5,2.8-1.1c1.1,0.5,1.5,1.7,1.1,2.8
	L28.3,59C27.8,60.1,26.6,60.5,25.5,60.1z M38.3,38.7c-0.3,0-0.6,0.1-0.8,0.2c-0.4,0.1-0.7,0.3-1,0.6c-0.1-0.3-0.3-0.4-0.6-0.6
	l1.9,39.7c0.3,0.2,0.8,0.3,1.5,0.3c9-0.5,17.8-3.9,24.8-10.3c0.6-0.5,0.7-0.9,0.8-1.3L38.3,38.7z M44.8,59c-1.1,0.4-2.3-0.2-2.7-1.3
	l-2.8-7.8c-0.4-1.1,0.2-2.3,1.3-2.7c0.7-0.3,1.5-0.1,2,0.4c0.3,0.2,0.5,0.5,0.6,0.9l2.8,7.8C46.5,57.4,45.9,58.6,44.8,59z"
      />
    </SvgIcon>
  );
};

export default CitricIcon;
