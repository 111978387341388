export const httpPrefix = (url: string) => {
  return !url.match(/^https?:\/\//i) ? `http://${url}` : url;
};

export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
};

export const escapeRegExp = (value: string) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const getFormattedDate = (dateString: string | undefined) => {
  if (dateString) {
    let auxDate = new Date(dateString);
    return `${auxDate.getDate().toString().length === 2 ? auxDate.getDate() : "0" + auxDate.getDate()}/${auxDate.getMonth() >= 9 ? (auxDate.getMonth() + 1) : "0" + (auxDate.getMonth() + 1)}/${auxDate.getFullYear()}`;
  } else {
    return null;
  }
};

export const getTimePassed = (dateString: string | undefined, t: any) => {
  if (dateString) {
    const SERVER_GMT = 1;
    const serverNow = new Date( new Date().getTime() + SERVER_GMT * 3600 * 1000);
    
    const delta: number = Math.abs((serverNow.getTime()) - (new Date(dateString).getTime()));

    let diff = {
      year: Math.floor(delta / 31536000000),
      month: Math.floor(delta / 2592000000),
      day: Math.floor(delta / 86400000),
      hour: Math.floor(delta / 3600000),
      minute: Math.floor(delta / 60000),
      second: Math.floor(delta / 1000),
    };

    if (diff.year !== 0) {
      return `${diff.year} ${diff.year > 1 ? t("years") : t("year")} ${t("ago")}`
    } else if (diff.month !== 0) {
      return `${diff.month} ${diff.month > 1 ? t("months") : t("month")} ${t("ago")}`
    } else if (diff.day !== 0) {
      return `${diff.day} ${diff.day > 1 ? t("days") : t("day")} ${t("ago")}`
    } else if (diff.hour !== 0) {
      return `${diff.hour} ${diff.hour > 1 ? t("hours") : t("hour")} ${t("ago")}`
    } else if (diff.minute !== 0) {
      return `${diff.minute} ${diff.minute > 1 ? t("minutes") : t("minute")} ${t("ago")}`
    } else {
      return t("less than a minute ago");
    }

  } else {
    return null;
  }
};

export const getMinutesPassed = (dateString: string) => {
  const delta: number = Math.abs((new Date().getTime()) - (new Date(dateString).getTime()));
  return Math.floor(delta / 60000);
};

export const getFormattedDateYear = (dateString: string | undefined) => {
  if (dateString) {
    let auxDate;
    
    if (dateString.indexOf("/") !== -1) {
      const parts = dateString.split("/");
      auxDate = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
    }  else {
      auxDate = new Date(dateString);
    }

    return auxDate.getFullYear();
  } else {
    return null;
  }
};

export const getFormattedTime = (dateString: string | undefined) => {
  if (dateString) {
    let auxDate = new Date(dateString);

    return auxDate.toLocaleString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    return null;
  }
};

export const formatTel = (phone: string) => {
  let unformattedPhone = phone;
  let formattedPhone = "";

  if (phone.indexOf("(") !== -1) {
    formattedPhone += phone.substring(0, phone.indexOf(")")) + " ";
    unformattedPhone = phone.substring(phone.indexOf(")"), phone.length);
  }

  for (let i = 0; i < unformattedPhone.length; i++) {
    formattedPhone += unformattedPhone.charAt(i);

    if (Number.isInteger((i + 1) / 3) && i + 1 < unformattedPhone.length) {
      formattedPhone += " ";
    }
  }

  return formattedPhone;
};

export const convertToRGA = (hex: string, alpha: number) => {
  const hexCode = hex.substring(1);
  const aRgbHex = hexCode.match(/.{1,2}/g);

  if (aRgbHex?.length === 3) {
    return `rgba(${parseInt(aRgbHex[0], 16)}, ${parseInt(aRgbHex[1], 16)}, ${parseInt(aRgbHex[2], 16)}, ${alpha})`;
  } else {
    return hex;
  }
};

export const stripHTML = (htmlText: string) => {
  return htmlText !== null ? htmlText.replace(/<.*?>/g, "") : " ";
};