import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import {
    Box,
    ClickAwayListener,
    Fade,
    IconButton,
    Paper,
    Popper,
    Theme,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CPopper = styled(Popper)`
    background-color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
    border-radius: 4px;
    padding: ${({ theme }: { theme: Theme }) => theme.spacing(5)};
    &[data-popper-placement="bottom"] {
        top: 0;
        left: 0;
        margin-top: 0px;
        margin-left: 12px;
        margin-right: 12px;
        z-index: ${({ theme }: { theme: Theme }) => theme.zIndex.drawer};
        &:before {
            transform-origin: 0% 100%;
        }
    }
    .popoverRoot {
        background-color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
        .close {
            position: absolute;
            top: -40px;
        }
        .arrow {
            width: 0;
            height: 0;
            top: -5px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
        }
        .content {
            color: ${({ theme }: { theme: Theme }) => theme.palette.primary.contrastText};
        }
    }
    &.noBG {        
        background-color: transparent !important;
        box-shadow: none;
        padding: 0px;
        .popoverRoot {
            background: #ffffff;
            .arrow {
                width: 12px;
                height: 12px;
                top: -5px;
                background: #ffffff;
                transform: translate(274px, 0px) rotate(-135deg) !important;
                box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
                border: none !important;
                z-index: 0;
            }
            .content {
                z-index: 1;
                position: relative;
                box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
            }
        }
    }
`;

interface CustomPopperProps {
    show: boolean;
    anchor: HTMLElement | null;
    clickAwayFn: any;
    maxWidth?: number;
    children: any;
    placeRight?: boolean;
    fullWidth?: Boolean;
    noBg?: boolean;
    styleContent?: any | null;
    noCloseBt?: boolean;
}

const CustomPopper = (props: CustomPopperProps) => {

    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

    const theme = useTheme();

    return (
        <CPopper
            theme={theme}
            open={props.show}
            anchorEl={props.anchor}
            placement="bottom"
            className={props.noBg ? "noBG" : ""}
            transition
            modifiers={[
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [
                            props.placeRight && props.maxWidth ?
                                -100 : 0,
                            10
                        ],
                    }
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        rootBoundary: 'window',
                    }
                },
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef,
                    }
                },
                {
                    name: 'flip',
                    enabled: false,
                    options: {
                        altBoundary: true,
                    },
                }
            ]
            }>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Box>
                        <ClickAwayListener onClickAway={
                            (event) => {
                                event.stopPropagation();
                                props.clickAwayFn();
                            }
                        }>
                            <Paper
                                className={"popoverRoot"}
                                sx={{
                                    maxWidth: props.maxWidth ? props.maxWidth : 'none',
                                    width: props.fullWidth && props.maxWidth ? props.maxWidth : "auto"
                                }}>
                                {
                                    !props.noCloseBt &&
                                    (
                                        <IconButton
                                            className={"close"}
                                            sx={{
                                                left: props.placeRight ? "-10px" : "unset",
                                                right: props.placeRight ? "unset" : "-10px"
                                            }}
                                            onClick={() => props.clickAwayFn()}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )
                                }
                                <span className={"arrow"} ref={setArrowRef} />
                                <Box className={"content"} {...props.styleContent}>
                                    {props.children}
                                </Box>
                            </Paper>
                        </ClickAwayListener>
                    </Box>
                </Fade>
            )}
        </CPopper>
    )

};

export default CustomPopper;