import { Delete, Get, GetBlob, Post, Put } from "../utils/axios";
import { Career } from "../models/Career";
import { CareerSpecialty } from "../models/CareerSpecialty";
import {
  SearchArticles,
  SearchIndication,
  SearchDCI,
  SearchItem,
  RecentSearch,
  SearchDrugsName,
  SearchPathologies,
  SearchAllergies,
  Agent,
  SearchInjectables,
  SearchSubsSol
} from "../models/Search";
import {
  foodInteractions,
  ItemInjectable,
  Monography,
  Package,
  physioInteractions,
} from "../models/Item";
import { Item } from "../models/Item";
import { InteractionResponse } from "../models/Interaction";
import { Guideline, GuideTree } from "../models/Guideline";
import { ClinicalAnalyze, ClinicalData } from "../models/Clinical";
import { Notification } from "../models/Notification";
import { ContainerData } from "../models/ContainerData";
import { DCIsReferences, References } from "../models/References";
import { Faculty } from "../models/User";
import { DILUTION_QUERY, EXACT_QUERY, DEFAULT_SKIP, DEFAULT_TAKE, IS_DRUG_QUERY, REBUILD_QUERY, SEARCH_QUERY, TOKEN_QUERY, DEFAULT_TAKE_SEARCH, DRUG_QUERY, PRODUCT_QUERY } from "../constants/routes";
import { InsightType } from "../models/Insights";

/**
 * CAREERS AND SPECIALTY
 */

export function getCareers(): Promise<[Career]> {
  return new Promise((resolve, reject) => {
    Get<Career[]>("/api/content/careers")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getCareerSpecialty(): Promise<[CareerSpecialty]> {
  return new Promise((resolve, reject) => {
    Get<CareerSpecialty[]>("/api/content/branches")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * DRUGS & PRODUCTS SEARCH BY TERM
 */

export function searchItemsByTerm(
  term: string,
  exact: boolean,
  searchDrugs: boolean,
  searchProducts: boolean,
  skip: number = 0,
  take: number = DEFAULT_TAKE_SEARCH
): Promise<SearchItem[]> {
  return new Promise((resolve, reject) => {
    Get<SearchItem[]>(`/api/items/search?${SEARCH_QUERY}=${encodeURIComponent(term)}&${EXACT_QUERY}=${exact}&${DRUG_QUERY}=${searchDrugs}&${PRODUCT_QUERY}=${searchProducts}&skip=${skip}&take=${take}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * DRUGS & PRODUCTS SEARCH BY AGENT
 */

export function searchItemsByAgent(
  term: string,
  token?: string | null,
): Promise<SearchItem[]> {
  return new Promise((resolve, reject) => {
    Get<SearchItem[]>(`/api/items/agent-search?${SEARCH_QUERY}=${encodeURIComponent(term)}${token !== null && token !== undefined ? `&${TOKEN_QUERY}=${token}` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * DRUGS & PRODUCTS SEARCH BY AGENT
 */

export function searchItemsByAgentId(
    id: number,
    drugSearch: boolean = true,
    productSearch: boolean = true,
  ): Promise<SearchItem[]> {
    return new Promise((resolve, reject) => {
      Get<SearchItem[]>(`/api/items/agent-search/${id}?drug=${drugSearch}&product=${productSearch}`)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          reject(response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

/**
 * DRUGS & PRODUCTS GET ITEM PACKAGES BY ID
 */

export function getItemPackages(
  id: any, isDrug: boolean,
  token?: string | null
): Promise<Package[]> {
  return new Promise((resolve, reject) => {
    Get<Package[]>(`/api/items/${id}/packages?${IS_DRUG_QUERY}=${isDrug}${token !== null && token !== undefined ? `&${TOKEN_QUERY}=${token}` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getItemPackageImage(
  id: any, isDrug: boolean, token?: string | null
): Promise<any> {
  return new Promise((resolve, reject) => {
    GetBlob<any>(`/api/items/${id}/image?${IS_DRUG_QUERY}=${isDrug}${token !== null && token !== undefined ? `&${TOKEN_QUERY}=${token}` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * GET DRUGS & PRODUCTS ITEM BY ID
 */

export function getItemById(
  id: number,
  isDrug: boolean,
  token?: string | null
): Promise<Item> {
  return new Promise((resolve, reject) => {
    Get<Item>(`/api/items/${id}?${IS_DRUG_QUERY}=${isDrug}${(token !== null && token !== undefined) ? `&${TOKEN_QUERY}=${token}` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * GET DRUGS & PRODUCTS ITEM INTERACTIONS
 */

export function getItemDrugsInteractions(
  id: number,
  skip: number = DEFAULT_SKIP,
  take: number  = DEFAULT_TAKE,
  search: string = "",
): Promise<InteractionResponse> {
  return new Promise((resolve, reject) => {
    Get<InteractionResponse>(`/api/items/${id}/drug-interactions?skip=${skip}&take=${take}&search=${search}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getItemFoodInteractions(
  id: number
): Promise<foodInteractions[]> {
  return new Promise((resolve, reject) => {
    Get<foodInteractions[]>(`/api/items/${id}/food-interactions`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getItemPhysioInteractions(
  id: number
): Promise<physioInteractions[]> {
  return new Promise((resolve, reject) => {
    Get<physioInteractions[]>(`/api/items/${id}/physiological-interactions`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/** 
 * GET DCIs REFERENCES
*/

export function getDCIsReferences(
  skip: number,
  take: number,
  id: string | null,
  name: string | null,
  token: string | null
): Promise<References[]> {
  return new Promise((resolve, reject) => {
    let queries: string[] = [];
    if (id !== null) { queries.push(`id=${id}`); }
    if (name !== null) { queries.push(`name=${name}`); }
    queries.push(`skip=${skip}`);
    queries.push(`take=${take}`);
    if (token !== null) { queries.push(`${TOKEN_QUERY}=${token}`); }

    Get<DCIsReferences>(`api/content/references?${queries.join("&")}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function getDrugByDCI(
    id: number,
    skip: number = 0,
    take: number = DEFAULT_TAKE_SEARCH,
): Promise<SearchItem[]> {
    return new Promise((resolve, reject) => {
        Get<SearchItem[]>(`api/dcis/${id}/items?skip=${skip}&take=${take}`)
        .then((response: any) => {
            if (response.status === 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      })
};

/**
 * GET DRUGS & PRODUCTS ITEM MONOGRAPHY
 */

export function getItemMonography(
  id: any,
  isDrug: boolean,
  token?: string | null
): Promise<Monography> {
  return new Promise((resolve, reject) => {
    Get<Monography>(`/api/items/${id}/monography?${IS_DRUG_QUERY}=${isDrug}${(token !== null && token !== undefined) ? `&${TOKEN_QUERY}=${token}` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * DCI's SEARCH BY TERM
 */

export function searchDCIByTerm(term: string, exact?: boolean): Promise<SearchDCI[]> {
  return new Promise((resolve, reject) => {
    Get<SearchDCI[]>(`/api/dcis/search?${SEARCH_QUERY}=${encodeURIComponent(term)}${exact ? `&${EXACT_QUERY}=true` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * THERAPEUTIC INDICATIONS SEARCH BY TERM
 */

export function searchIndicationsByTerm(
  term: string
): Promise<SearchIndication[]> {
  return new Promise((resolve, reject) => {
    Get<SearchIndication[]>(`/api/indications/search?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * GUIDELINES SEARCH BY TERM
 */

export function searchGuidelinesByTerm(
  term: string
): Promise<Guideline[]> {
  return new Promise((resolve, reject) => {
    Get<Guideline[]>(`/api/guidelines/search?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * ARTICLES SEARCH BY TERM
 */

export function searchArticlesByTerm(term: string): Promise<SearchArticles[]> {
  return new Promise((resolve, reject) => {
    Get<SearchArticles[]>(`/api/articles/search?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * AGENTS SEARCH BY TERM
 */

export function searchAgentsByTerm(term: string): Promise<Agent[]> {
  return new Promise((resolve, reject) => {
    Get<Agent[]>(`/api/agents/search?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getAgentsByToken(token: string): Promise<Agent> {
  return new Promise((resolve, reject) => {
    Get<Agent>(`/api/agents?${TOKEN_QUERY}=${encodeURIComponent(token)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * AGENT PRODUCTS INSIGHTS
 */

export function getAgentInsights(token: string): Promise<InsightType> {
  return new Promise((resolve, reject) => {
    Get<InsightType>(`/api/agents/insights?${TOKEN_QUERY}=${encodeURIComponent(token)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * GUIDELINES
 */

export function getGuidelinesTree(): Promise<GuideTree[]> {
  return new Promise((resolve, reject) => {
    Get<GuideTree[]>(`/api/guidelines/tree`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function getGuidelineByPatId(guideId: any): Promise<Guideline[]> {
  return new Promise((resolve, reject) => {
    Get<Guideline[]>(`/api/guidelines/pathology/${guideId}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * CLASSIFICATIONS ATC & PRODUCTS
 */

export function getClassifications(classificationType: string): Promise<any[]> {
  return new Promise((resolve, reject) => {
    Get<any[]>(`/api/content/${classificationType}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * RECENT SEARCHED ITEMS
 */

export function getRecentsSearched(): Promise<RecentSearch[]> {
  return new Promise((resolve, reject) => {
    Get<RecentSearch[]>("/api/users/search-history")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function setAsRecentSearched(
  itemId: any,
  itemDrug: boolean
): Promise<RecentSearch[]> {
  return new Promise((resolve, reject) => {
    Post<RecentSearch[]>(
      `/api/users/search-history/${itemId}?${IS_DRUG_QUERY}=${itemDrug}`,
      { isDrug: itemDrug }
    )
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * DRUGS NAME SEARCH BY TERM
 */

export function searchForDrugName(term: string): Promise<SearchDrugsName[]> {
  return new Promise((resolve, reject) => {
    Get<SearchDrugsName[]>(`/api/items/search-names?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * PATHOLOGIES & ALLERGIES
 */

export function searchPathologies(term: string): Promise<SearchPathologies[]> {
  return new Promise((resolve, reject) => {
    Get<SearchPathologies[]>(`/api/content/pathologies?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function searchAllergies(term: string): Promise<SearchAllergies[]> {
  return new Promise((resolve, reject) => {
    Get<SearchAllergies[]>(`/api/content/allergies?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}


/**
 * CLINICAL CHECKER
 */

export function analyseClinical(data: ClinicalData): Promise<ClinicalAnalyze> {
  return new Promise((resolve, reject) => {
    Post<ClinicalAnalyze>(`/api/clinical`, data)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * INJECTABLE PRESCRIPTIONS
 */

export function searchInjectables(term: string, rebuild: string, dilution: string): Promise<SearchInjectables[]> {
  return new Promise((resolve, reject) => {
    Get<SearchInjectables[]>(`/api/injectables/search?${SEARCH_QUERY}=${encodeURIComponent(term)}${rebuild.length ? `&${REBUILD_QUERY}=${rebuild}` : ""}${dilution.length ? `&${DILUTION_QUERY}=${dilution}` : ""}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getInjectInfo(id: any): Promise<ItemInjectable> {
  return new Promise((resolve, reject) => {
    Get<ItemInjectable>(`/api/injectables/${encodeURIComponent(id)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/**
 * COMPATIBLE IV
 */

export function searchSubsOrSol(term: string): Promise<SearchSubsSol[]> {
  return new Promise((resolve, reject) => {
    Get<SearchSubsSol[]>(`/api/iv/search?${SEARCH_QUERY}=${encodeURIComponent(term)}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        } 
        if (response.status === 204) {
          resolve([]);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getIVInfo(query: string): Promise<any> {

  return new Promise((resolve, reject) => {
    Get<any>(`/api/iv/info?${query}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * NOTIFICATIONS
 */

export function GetNotifications(): Promise<Notification[]> {
  return new Promise((resolve, reject) => {
    Get<Notification[]>(`/api/notifications`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function GetNotificationById(id: number): Promise<Notification> {
  return new Promise((resolve, reject) => {
    Get<Notification>(`/api/notifications/${id}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function CreateNotification(data: any): Promise<Notification> {
  return new Promise((resolve, reject) => {
    Post<Notification>(`/api/notifications`, data)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function UpdateNotification(data: Notification): Promise<Notification> {
  return new Promise((resolve, reject) => {
    Put<Notification>(`/api/notifications/${data.id}`, data)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function DeleteNotification(id: any) {
  Delete(`/api/notifications/${id}`);
};

export function setAsViewed(id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    Put<any>(`/api/notifications/${id}/viewed`, null)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/*
* CONTAINER (ADMINISTRATIVE PURPOSES)
*/

export function getContainerData(): Promise<ContainerData> {
  return new Promise((resolve, reject) => {
    Get<Package[]>("/api/content/container")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

/*
 * FACULDADES 
 */

export function getFaculties(): Promise<Faculty[]> {
  return new Promise((resolve, reject) => {
    Get<Faculty[]>(`/api/content/faculties`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};