import styled from "@emotion/styled/macro";
import {
  CssBaseline,
  Box,
  useTheme,
  Theme
} from "@mui/material";
import Footer from '../components/layout/Footer';
import Header from "../components/layout/Header";

const Root = styled.div`
  margin: 0 auto;
  min-height: 100%;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
  }
`;

const AuthLayout = ({ children }: { children: any }) => {
  const theme = useTheme();

  return (
    <Root theme={theme}>
      <Header />
      <CssBaseline />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
      }}>
        {children}
      </Box>
      <Footer mw showSocial />
    </Root>
  );
};

export default AuthLayout;
