import { useTranslation } from "react-i18next";

export enum Role {
  Admin,
  Entity,
}

export function GetRoleName(role: Role | undefined) {
  const { t }: { t: any } = useTranslation();

  switch (role) {
    case Role.Admin: {
      return t("Administrator");
    }
    default: {
      return t("User");
    }
  }
}
