import React from "react";
import { SvgIcon } from "@mui/material";

const SearchIcon = (props: any) => {

  return (
    <SvgIcon {...props} viewBox="0 0 173.1 172.8">
      <path d="M100.5,100.5c-9.6,9.6-22.3,14.4-34.9,14.4c-12.6,0-25.2-4.8-34.9-14.4c-9.6-9.6-14.4-22.3-14.4-34.9
            c0-12.6,4.8-25.2,14.4-34.9c9.6-9.6,22.2-14.4,34.9-14.4c12.6,0,25.2,4.8,34.9,14.4c9.6,9.6,14.4,22.3,14.4,34.9
            C114.9,78.2,110.1,90.9,100.5,100.5z M139,122.5c-0.8-0.8-2-0.8-2.8,0l-0.6,0.6l-17.8-17.8c8.9-11.7,13.4-25.7,13.4-39.7
            c0-16.8-6.4-33.6-19.2-46.4C99.2,6.4,82.4,0,65.6,0C48.8,0,32,6.4,19.2,19.2C6.5,31.9,0,48.5,0,65.1C0,73.6,1.6,82,4.8,90
            c3.2,8,8,15.5,14.5,22c12.8,12.8,29.6,19.2,46.4,19.2c14,0,28-4.5,39.7-13.4l17.8,17.8l-0.3,0.3c-0.8,0.8-0.8,2,0,2.8l33.5,33.5
            c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l13.5-13.5c0.4-0.4,0.6-0.9,0.6-1.4c0-0.5-0.2-1-0.6-1.4L139,122.5z"/>
      <path d="M96,54.1H77.1V35.3c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6H56c-0.5,0-1,0.2-1.4,0.6
            c-0.4,0.4-0.6,0.9-0.6,1.4v18.8H35.2c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4v19.1c0,0.5,0.2,1,0.6,1.4
            c0.4,0.4,0.9,0.6,1.4,0.6h18.8V96c0,0.5,0.2,1,0.6,1.4C55,97.7,55.5,98,56,98h19.1c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
            V77.1H96c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4V56.1c0-0.5-0.2-1-0.6-1.4C97,54.3,96.5,54.1,96,54.1z"/>
    </SvgIcon>
  );

}

export default SearchIcon;