import { THEMES } from "../../constants";
import { PaletteOptions } from "@mui/material/styles";
import { ComponentsProps } from "@mui/material";
import { customWhite, customBlack, customBlue, customSuccess, customWarning, customError } from './colors';

const simposiumVariant: {
  name: string;
  palette: PaletteOptions;
  props: ComponentsProps;
  header: any;
  footer: any;
  overrides: any;
  shape: any;
  sidebar: any;
} = {
  name: THEMES.SIMPOSIUM,
  palette: {
    primary: {
      light: customWhite[100],
      main: customBlue[50],
      dark: customBlue[100],
      contrastText: customWhite[50]
    },
    secondary: {
      main: customBlue[100], 
      contrastText: customWhite[50]
    },
    text: {
      primary: customBlack[100],
      secondary: customBlack[100],
      disabled: customWhite[200],
    },
    error: {
      main: customError[50],
      light: customError[100]
    },
    warning: {
      light: customWarning[50],
      main: customWarning[100]
    },
    success: {
      main: customSuccess[50],
      light: customSuccess[100]
    },
    background: {
      default: customWhite[100],
      paper: customWhite[50],
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  header: {
    color: customWhite[100],
    background: customWhite[50],
    search: {
      color: customWhite[100],
    },
    indicator: {
      background: customBlue[50],
    },
  },
  footer: {
    color: customWhite[100],
    background: customWhite[50],
  },
  sidebar: {
    color: customWhite[50],
    background: customBlue[100],
    header: {
      color: customWhite[100],
      background: customBlue[100],
      brand: {
        color: customWhite[100],
      },
    },
    footer: {
      color: customWhite[50],
      background:customBlue[100],
      online: {
        background: customWhite[100],
      },
    },
    badge: {
      color: customWhite[50],
      background: customBlue[100],
    },
  }
};

export default simposiumVariant;